<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { debounce } from "@/helpers/debounce";


export default {
  page: {
    title: "Laporan Asesmen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "No. Jadwal Sertifikasi", name: "-" },
      { width: "100px", label: "Tanggal Jadwal Sertifikasi", name: "-" },
      { width: "auto", label: "Skema Sertifikasi", name: "-" },
      { width: "auto", label: "Nama Asesi", name: "-" },
      { width: "auto", label: "Nama Asesor", name: "-" },
      { width: "50px", label: "Rekomendasi (K/BK)", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
      // { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Asesmen Selesai",
      items: [
        {
          text: "Laporan",
          href: "/",
        },
        {
          text: "Asesmen Selesai",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      // variable Page Table
      loadingTable: false,
      tanggal_dari: null,
      tanggal_sampai: null,
     
      table_data: [],
      columns: columns,
      sortKey: "id", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        per_page: 10,
        search: "",
        sort_field: 0,
        sort_order: "desc",
        filter_status: "ENABLE", // untuk filtering data
        provinsi_id: null
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },

      // variabel referensi
      optionsProvinsi: [],

      // variabel page
      provinsi_selected: "",
    };
  },
  created() {
    this.searchDatatable = debounce(() => {
      this.getDataTable();
      // call fetch API to get results
    }, 500);
  },
  mounted() {
    let self = this;
    self.getDataTable();
    // self.getProvinsi();

  },
  methods: {
    fullDateTimeFormat(date) {
        moment.locale('id');
        return moment(date).format("DD MMMM YYYY") 
    },
    getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "laporan/asesmen-selesai") {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: self.sessionRoleId
        },
      };
      axios(config)
      .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
                  self.table_data = response_data_fix.list_data.data;
                  // self.configPagination(response_data_fix.list_data);
                  self.currentTablePage = response_data_fix.list_data.current_page;
                  self.loadingTable = false;
              
              self.loadingTable = false;
          } else {
              Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response_data_fix.data.message,
              });
          }
      })
      .catch((errors) => {
          console.log(errors);
      });
        
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    changeStatusData(status, id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah Anda yakin untuk mengubah status?";
      } else {
        text_alert = "Apakah Anda yakin untuk mengubah status?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kabkota/" + id + '/status',
            data: {
              id: id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil diperbarui.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kabkota/" + id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    toPage: function (url) {
      let self = this;
      self.getDataTable(url);
    },
    filterDatatable(){
      let self = this;
      self.tableData.provinsi_id = self.provinsi_selected?.id;
      self.getDataTable();
    },
    exportExcel(){
        let self = this;
        let config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "laporan/asesmen-selesai/excel",
            params: self.tableData,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
            responseType: 'blob',
        };
        axios(config)
            .then(function (response) {
                var blob = new Blob([response.data], { type: 'application/vnd-ms-excel' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Daftar Laporan Asesmen Selesai.xls'
                link.click()
            })
            .catch(function (error) {
                console.log(error);
            });  
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Daftar Asesmen Selesai</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  &nbsp;
                  <button class="btn btn-sm btn-success m-1" @click="exportExcel"><i class="fas fa-file-excel"></i> Export Data</button>
                </div>
              </div>
              <div class="col-md-12">
                <hr />
                <div class="row">
                  <div class="form-group col-md-2">
                      <label for="statusTable">Tanggal Dari</label>
                      <input type="date" v-model="tanggal_dari" id="" class="form-control">
                  </div>
                  <div class="form-group col-md-2">
                      <label for="statusTable">Tanggal Sampai</label>
                      <input type="date" v-model="tanggal_sampai" id="" class="form-control">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="statusTable">Status</label>
                    <select
                      class="form-select"
                      id="statusTable"
                      v-model="tableData.filter_status"
                    >
                      <option value="ENABLE">ENABLE</option>
                      <option value="DISABLE">DISABLE</option>
                    </select>
                  </div>
                  <div class="col-md-1">
                    <br />
                    <div class="btn btn-primary btn-sm mt-2" v-on:click="filterDatatable">
                      <i class="fa fa-filter"></i> Filter
                    </div>
                  </div>  
                </div>
                <hr />
              </div>
              <div class="col-md-12 mb-2">
                <div class="row mb-2">
                  <div class="col-md-4">
                    <table>
                      <tr>
                        <td>Show</td>
                        <td>
                          <select
                            class="form-control form-control-sm"
                            id="showpaginatetable"
                            style="width: 100%"
                            v-model="tableData.per_page"
                            @change="getDataTable()"
                          >
                            <option value="10" selected>10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                          </select>
                        </td>
                        <td>Entries</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-5">&nbsp;</div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchTable"
                      placeholder="Cari Data ..."
                      v-model="tableData.search"
                      @input="searchDatatable()"
                    />
                  </div>
                </div>
                <datatable :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy">
                  <tbody>
                    <tr v-if = "loadingTable">
                      <td colspan="7" class="text-center">
                        <i class="fa fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if = "table_data.length == 0">
                      <td colspan="7" class="text-center">
                        Data tidak ditemukan
                      </td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                      <td class="text-center">
                        {{ ((parseInt(tableData.per_page) * parseInt(currentTablePage)) - parseInt(tableData.per_page)) + key_data + 1}}.
                      </td>
                      <td class="text-center">{{ row_data.asesmen?.nomor_asesmen }}</td>
                      <td class="">{{ row_data.rapat_pleno_tanggal }}</td>
                      <td class="text-center">{{ row_data.skema_nama }}</td>
                      <td>{{row_data.no_registrasi}} - {{ row_data.asesi_nama }}</td>
                      <td class="">{{ row_data.asesmen_asesor?.asesor?.kode_asesor }} - {{ row_data.asesmen_asesor?.asesor_nama }}</td>
                      <td class="text-center">{{ row_data.rekomendasi ?? 'Data Tidak Tersedia' }}</td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
